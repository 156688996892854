import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'calsoft-footer-header',
  templateUrl: './footer-header.component.html',
  styleUrls: ['./footer-header.component.scss']
})
export class FooterHeaderComponent {


  @Input() data: any;
  display: boolean;
  

  constructor(
    private commonService:CommonService,
    private configService:ConfigService,
    public router: Router,
    ) {

    }
  ngOnInit() {

    if(this.router.url == '/' || this.router.url == '/home'){

      this.display=true;
    }else{
      this.display=false;
    }

    
  }

  callPhone() {
    let phoneNumber = "tel:" + this.data.footerScrollCallUsNo;
    window.location.href = phoneNumber;
  }


  whatsapp() {
    // let currentURL = window.location.href
    // let url = `https://web.whatsapp.com/send?text=${currentURL}`
    // window.open(url, '_blank');

    let message = `Hi Sir`;
    let url = `https://api.whatsapp.com/send?phone= ${encodeURIComponent(this.data.storePhoneNumber)}&text=${encodeURIComponent(message)}`;
    this.commonService.goToLink(url);

  }


}
