import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnChanges {

  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  @Input() index: any;
  @Input() indexInital: any;
  display: boolean = false;
  initForm: boolean = true;
  @Input() indexInitial:any=0;
  @Output() valueChange = new EventEmitter<any>();
  reInit: boolean=false;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.field.currentValue && changes.field.currentValue.customfield2) {
      this.imageValues = JSON.parse(this.field.customfield2);
     
    }
    
  }

  public serverPath = environment.commonImageApi + '/formImage/';

  imageValues: any = [];



  ngOnInit() {

 
    if(this.indexInitial>this.index){


    }

    if (this.group != undefined) {
      this.display = true;
    }

    if (this.field && this.field.customfield2) {
      this.imageValues = JSON.parse(this.field.customfield2);
     
    }

  
  
    this.reInit=true;


  }


  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }

  setValue(value){
    this.group.get(this.field.name).setValue(value);

   this.indexInitial=this.index+1;
 this.valueChange.emit(this.field.name);

  }

}
