<div class="img" *ngIf="reInit" [formGroup]="group">
    <div class="title">
        <h2 class="padding-10">
            {{ field.name | titlecase }}
        </h2>
    </div>

    <div class="image-container" fxLayout="row"   fxLayoutGap="10px"  fxLayoutAlign="space-around center">
        <ng-container *ngFor="let image of imageValues">
            <img (click)="setValue(image.value)" class="icon"  fxFlexAlign="stretch" [alt]="field.customfield3" [src]="serverPath + image.image">
        </ng-container>
    </div>
</div>
