import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  constructor(private http: HttpClient,
    private router:Router
  ) { }

  url = environment.serverAPI + "/api/catalog";

  url9 = environment.serverAPI2 + '/api/core/download/pdf';

  url2 = environment.serverAPI4 + '/' + 'api/product/solr';

  RelatedProducts(id, fliterlist: any, Page: number, size: number): Observable<Object> {
    let url = environment.serverAPI + "/api/catalog/productsPagination?categoryId=" + id + "&page=" + Page + "&size=" + size;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${url}`, fliterlist, { headers });
  }

  getProductDetailById(id: any): Observable<Object> {
    return this.http.get(`${this.url}/product/id?productId=${id}`);
  }

  getProductImage(productId: number): Observable<Object> {
    let url = environment.serverAPI + "/api/catalog/images/" + productId;
    return this.http.get(`${url}`);
  }



  getProductBySearch(query, Page: any, size: any, sort: any): Observable<Object> {

    let payload = {
      "searchQuery": query,
    }
    return this.http.post(`${this.url}/search?page=${Page}&size=${size}&sort=${sort}`, payload);
  }

  getProductByCustomProductId(query, Page: any, size: any, sort: any): Observable<Object> { 
    query = query;
    const queryList = query.split(',').map(Number);
    //console.log(queryList);
    let payload = {
      "productIds": queryList,
    }
    return this.http.post(`${this.url}/custom?page=${Page}&size=${size}&sort=${sort}`, payload);
  }


  // session request response service
  getSessionDetails(query): Observable<Object> {

    if(this.router.url.includes('/product/')){
      let url = environment.serverAPI2 + "/api/core/session/push";
      return this.http.post(`${url}`, query);
    }
    
  }

  productsByCategoryIdx(name: any, Page: number, size: number, sort: any): Observable<Object> {
    if (name.includes("&")) {
      name = name.replace(/&/g, 'plusAnd');
    }
    let url = environment.serverAPI + "/api/catalog/productsPagination?categoryName=" + name + "&page=" + Page + "&size=" + size + "&sort=" + sort;
    return this.http.get(`${url}`);
  }

  filterByCategoryId(id, fliterlist: any, Page: number, size: number, sort: any): Observable<Object> {
    let url = environment.serverAPI + "/api/catalog/productsPagination?categoryId=" + id + "&page=" + Page + "&size=" + size + "&sort=" + sort;
    return this.http.post(`${url}`, fliterlist);
  }

  getHomePageProduct(Page: any, size: any,value:any): Observable<Object> {
    return this.http.get(`${this.url}/home/${value}?page=${Page}&size=${size}`);
  }

  
  getcustomProductValue(values: any): Observable<Object> {
    return this.http.get(`${this.url}/customProduct?value=${values}`);
  }

  
  productLinked(linkedProduts): Observable<Response[]> {
		return this.http.post<Response[]>(`${this.url}/product/linked/all`, linkedProduts);
	}


  downloadPdf(fileName): Observable<any> {

		return this.http.get(`${this.url9}?filename=${fileName}`, { responseType: 'arraybuffer', observe: 'response' });
	
	}

  getProducts(Page: any, size: any, sort: any): Observable<Object> {
		return this.http.get(`${this.url2}?page=${Page}&size=${size}&sort=${sort}`);
	}


}
