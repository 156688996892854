import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-city-selection',
  templateUrl: './city-selection.component.html',
  styleUrls: ['./city-selection.component.scss']
})
export class CitySelectionComponent {
  selectedCity: any; // Variable to hold the currently selected city
  searchQuery: string = '';
  showAllPopular: boolean = false;
  showAllMore: boolean = false;
  // Updated popular cities from Tamil Nadu
  popularCities = [
    { name: 'Chennai', image: 'assets/city-icons/Chennaicityicon.jpg' },
    { name: 'Coimbatore', image: 'path/to/coimbatore-image.jpg' },
    { name: 'Madurai', image: 'path/to/madurai-image.jpg' },
    { name: 'Tiruchirappalli', image: 'path/to/tiruchirappalli-image.jpg' },
    { name: 'Salem', image: 'path/to/salem-image.jpg' },
    { name: 'Tirunelveli', image: 'path/to/tirunelveli-image.jpg' },
    { name: 'Tiruppur', image: 'path/to/tiruppur-image.jpg' },
    { name: 'Ranipet', image: 'path/to/ranipet-image.jpg' },
    { name: 'Nagercoil', image: 'path/to/nagercoil-image.jpg' },
    { name: 'Thanjavur', image: 'path/to/thanjavur-image.jpg' },
  ];

  // Updated more cities from Tamil Nadu
  moreCities = [
    { name: 'Vellore', image: 'path/to/vellore-image.jpg' },
    { name: 'Kancheepuram', image: 'path/to/kancheepuram-image.jpg' },
    { name: 'Erode', image: 'path/to/erode-image.jpg' },
    { name: 'Tiruvannamalai', image: 'path/to/tiruvannamalai-image.jpg' },
    { name: 'Pollachi', image: 'path/to/pollachi-image.jpg' },
    { name: 'Rajapalayam', image: 'path/to/rajapalayam-image.jpg' },
    { name: 'Sivakasi', image: 'path/to/sivakasi-image.jpg' },
    { name: 'Pudukkottai', image: 'path/to/pudukkottai-image.jpg' },
    { name: 'Neyveli (TS)', image: 'path/to/neyveli-ts-image.jpg' },
    { name: 'Nagapattinam', image: 'path/to/nagapattinam-image.jpg' },
    { name: 'Viluppuram', image: 'path/to/viluppuram-image.jpg' },
    { name: 'Tiruchengode', image: 'path/to/tiruchengode-image.jpg' },
    { name: 'Vaniyambadi', image: 'path/to/vaniyambadi-image.jpg' },
    { name: 'Theni Allinagaram', image: 'path/to/theni-allinagaram-image.jpg' },
    { name: 'Udhagamandalam', image: 'path/to/udhagamandalam-image.jpg' },
    { name: 'Aruppukkottai', image: 'path/to/aruppukkottai-image.jpg' },
    { name: 'Paramakudi', image: 'path/to/paramakudi-image.jpg' },
    { name: 'Arakkonam', image: 'path/to/arakkonam-image.jpg' },
    { name: 'Virudhachalam', image: 'path/to/virudhachalam-image.jpg' },
    { name: 'Srivilliputhur', image: 'path/to/srivilliputhur-image.jpg' },
    { name: 'Tindivanam', image: 'path/to/tindivanam-image.jpg' },
    { name: 'Virudhunagar', image: 'path/to/virudhunagar-image.jpg' },
    { name: 'Karur', image: 'path/to/karur-image.jpg' },
    { name: 'Valparai', image: 'path/to/valparai-image.jpg' },
    { name: 'Sankarankovil', image: 'path/to/sankarankovil-image.jpg' },
    { name: 'Tenkasi', image: 'path/to/tenkasi-image.jpg' },
    { name: 'Palani', image: 'path/to/palani-image.jpg' },
    { name: 'Pattukkottai', image: 'path/to/pattukkottai-image.jpg' },
    { name: 'Tirupathur', image: 'path/to/tirupathur-image.jpg' },
    { name: 'Ramanathapuram', image: 'path/to/ramanathapuram-image.jpg' },
    { name: 'Udumalaipettai', image: 'path/to/udumalaipettai-image.jpg' },
    { name: 'Gobichettipalayam', image: 'path/to/gobichettipalayam-image.jpg' },
    { name: 'Thiruvarur', image: 'path/to/thiruvarur-image.jpg' },
    { name: 'Thiruvallur', image: 'path/to/thiruvallur-image.jpg' },
    { name: 'Panruti', image: 'path/to/panruti-image.jpg' },
    { name: 'Namakkal', image: 'path/to/namakkal-image.jpg' },
    { name: 'Thirumangalam', image: 'path/to/thirumangalam-image.jpg' },
    { name: 'Vikramasingapuram', image: 'path/to/vikramasingapuram-image.jpg' },
    { name: 'Nellikuppam', image: 'path/to/nellikuppam-image.jpg' },
    { name: 'Rasipuram', image: 'path/to/rasipuram-image.jpg' },
    { name: 'Tiruttani', image: 'path/to/tiruttani-image.jpg' },
    { name: 'Nandivaram-Guduvancheri', image: 'path/to/nandivaram-guduvancheri-image.jpg' },
    { name: 'Periyakulam', image: 'path/to/periyakulam-image.jpg' },
    { name: 'Pernampattu', image: 'path/to/pernampattu-image.jpg' },
    { name: 'Vellakoil', image: 'path/to/vellakoil-image.jpg' },
    { name: 'Sivaganga', image: 'path/to/sivaganga-image.jpg' },
    { name: 'Vadalur', image: 'path/to/vadalur-image.jpg' },
    { name: 'Rameshwaram', image: 'path/to/rameshwaram-image.jpg' },
    { name: 'Tiruvethipuram', image: 'path/to/tiruvethipuram-image.jpg' },
    { name: 'Perambalur', image: 'path/to/perambalur-image.jpg' },
    { name: 'Usilampatti', image: 'path/to/usilampatti-image.jpg' },
    { name: 'Vedaranyam', image: 'path/to/vedaranyam-image.jpg' },
    { name: 'Sathyamangalam', image: 'path/to/sathyamangalam-image.jpg' },
    { name: 'Puliyankudi', image: 'path/to/puliyankudi-image.jpg' },
    { name: 'Nanjikottai', image: 'path/to/nanjikottai-image.jpg' },
    { name: 'Thuraiyur', image: 'path/to/thuraiyur-image.jpg' },
    { name: 'Sirkali', image: 'path/to/sirkali-image.jpg' },
    { name: 'Tiruchendur', image: 'path/to/tiruchendur-image.jpg' },
    { name: 'Periyasemur', image: 'path/to/periyasemur-image.jpg' },
    { name: 'Sattur', image: 'path/to/sattur-image.jpg' },
    { name: 'Vandavasi', image: 'path/to/vandavasi-image.jpg' },
    { name: 'Tharamangalam', image: 'path/to/tharamangalam-image.jpg' },
    { name: 'Tirukkoyilur', image: 'path/to/tirukkoyilur-image.jpg' },
    { name: 'Oddanchatram', image: 'path/to/oddanchatram-image.jpg' },
    { name: 'Palladam', image: 'path/to/palladam-image.jpg' },
    { name: 'Vadakkuvalliyur', image: 'path/to/vadakkuvalliyur-image.jpg' },
    { name: 'Tirukalukundram', image: 'path/to/tirukalukundram-image.jpg' },
    { name: 'Uthamapalayam', image: 'path/to/uthamapalayam-image.jpg' },
    { name: 'Surandai', image: 'path/to/surandai-image.jpg' },
    { name: 'Sankari', image: 'path/to/sankari-image.jpg' },
    { name: 'Shenkottai', image: 'path/to/shenkottai-image.jpg' },
    { name: 'Vadipatti', image: 'path/to/vadipatti-image.jpg' },
    { name: 'Sholavandan', image: 'path/to/sholavandan-image.jpg' },
    { name: 'Viswanatham', image: 'path/to/viswanatham-image.jpg' },
  ];

  filteredPopularCities = [...this.popularCities];
  filteredMoreCities = [...this.moreCities];

  get displayedPopularCities() {
    return this.showAllPopular ? this.filteredPopularCities : this.filteredPopularCities.slice(0, 10);
  }

  get displayedMoreCities() {
    return this.showAllMore ? this.filteredMoreCities : this.filteredMoreCities.slice(0, 8);
  }

  constructor(public dialogRef: MatDialogRef<CitySelectionComponent>) {}

  filterCities() {
    const query = this.searchQuery.toLowerCase();
    this.filteredPopularCities = this.popularCities.filter(city => city.name.toLowerCase().includes(query));
    this.filteredMoreCities = this.moreCities.filter(city => city.name.toLowerCase().includes(query));
    this.showAllPopular = false;
    this.showAllMore = false;
  }

  showMorePopular() {
    this.showAllPopular = true;
  }

  showMoreCities() {
    this.showAllMore = true;
  }

  selectCity(city: any) {
    this.selectedCity = city.name;
    this.dialogRef.close(city);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}


