<div *ngIf="display">
    <mat-toolbar style="border-bottom: 1px solid #cbc0c0; height: auto;" fxHide fxShow.gt-md class=" app-toolbar bg"
        fxLayoutAlign="space-between center">
        <mat-toolbar-row class="theme-container adj" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="20">


                <section>

                    <calsoft-logo fxFlex="70" [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>

            </div>

            <div fxLayout="row">

                <calsoft-Menu class="padding-10" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data"
                    [type]="'one'" [categoryArray]="item"></calsoft-Menu>

                <div class="search-bar" fxLayoutAlign="end center" *ngIf="url != '/cart'">
                    <calsoft-search3 *ngIf="data.disableSearchInHeader==0"></calsoft-search3>
                </div>

                <div fxLayoutAlign="end center" *ngIf="url != '/cart'">
                    <calsoft-cart-icon [color]="true"></calsoft-cart-icon>
                </div>

                <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                    <div *ngIf="data.disableLoginHeader==0">
                    <button mat-mini-fab style="background-color: #e0e0e0;  width: 40px; height: 40px;"
                        *ngIf="!userLoggedIn">
                        <mat-icon style="color: black;">person</mat-icon>
                    </button>
                    </div>
                </div>

                <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" [color]="'white'"
                    *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>



            </div>
        </mat-toolbar-row>

    </mat-toolbar>
    <mat-toolbar fxHide fxShow.gt-md>

    </mat-toolbar>

    <div *ngIf="data.headerEnabled && data.headerBannerRotateEnable"
        [style.backgroundImage]="'url(' + serverPath2 + data.headerLogoSrc + ')'"
        class="promo-top w-100 h-auto text-center theme-container">
        <div class="copy-container" [ngClass]="data.headerBannerRotateEnable == 1 ? 'marquee-text' : ''">
            <div class="flex-container">
                <h4 [style.color]="data.headerBannerTextColour"
                    class="font-weight-normal text-uppercase text-white m-0">
                    {{data.headerNotificationContent}}
                </h4> &nbsp;
            </div>
        </div>
    </div>

</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>

        

</div>