<div class="city-selection-dialog">
  <div class="dialog-header">
    <h2>Select a city</h2>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="search-bar">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Search for a city</mat-label>
      <input matInput [(ngModel)]="searchQuery" (input)="filterCities()">
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="popular-cities">
    <h3>Popular Cities</h3>
    <div class="city-grid">
      <div *ngFor="let city of displayedPopularCities" class="city-item popular" (click)="selectCity(city)">
        <!-- <img [src]="city.image" alt="{{ city.name }}"> -->
        <span class="padding-10">{{ city.name }}</span>
      </div>
      <div *ngIf="filteredPopularCities.length > 10" (click)="showMorePopular()" class="show-more">
        <span>Show More</span>
      </div>
    </div>
  </div>
  <div class="more-cities">
    <h3>More Cities</h3>
    <div class="city-grid">
      <div *ngFor="let city of displayedMoreCities" class="city-item more" (click)="selectCity(city)">
        <span>{{ city.name }}</span>
      </div>
      <!-- <div *ngIf="filteredMoreCities.length > 8" (click)="showMoreCities()" class="show-more">
        <span>Show More</span>
      </div> -->
    </div>
  </div>
</div>
