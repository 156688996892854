<div *ngIf="display">
    <header class="header header-28 bg-transparent theme-container" fxHide fxShow.gt-md>

        <div class="header-top font-weight-normal text-light">
            <div [class]="containerClass">
                <div class="header-left">
                    <div class="header-dropdown">
                        <div class="top-menu">
                            <div *ngIf="data.showContactNumber==1" (click)="callPhone()" style=" cursor: pointer;">
                                <span style=" cursor: pointer;">Call: {{data.storePhoneNumber}}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="header-right header-dropdown">
                    <ul class="top-menu">
                        <li>
                            <ul>
                                <div *ngIf="languageSelection==1">
                                    <calsoft-language></calsoft-language>
                                </div>
                                <!-- <li *ngIf="data.showContactNumber==1" (click)="callPhone()" style=" cursor: pointer;"><a> Call: {{data.storePhoneNumber}}</a></li> -->

                                <li *ngIf="data.disableLoginHeader==0" class="login" style=" cursor: pointer;"><a *ngIf="!userLoggedIn"
                                        (click)="SignIn('login')"><i class="icon-user"></i>Sign In / Sign Up</a></li>
                                <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center"
                                    [color]="'white'" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

            <div fxFlex="7">

            </div>

            <div fxLayout="row" fxLayoutGap="10px">
                <section class="padding-10">

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="center center">
                <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="end center"
                    fxFlex fxFlex.gt-sm="400px">
                    <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>

            </div>


            <div fxLayoutAlign="end center" fxFlex="10"
                *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

                <div *ngIf="data.disableWishlistHeader==0">
                <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                    <mat-icon>favorite_border</mat-icon>

                </button>
                </div>

                <calsoft-cart-icon [color]="true" fxFlex></calsoft-cart-icon>

            </div>

            <div fxFlex="7">

            </div>

        </mat-toolbar-row>

        <div fxLayout="row">
            <div fxFlex="25">
                <div fxLayout="row" fxLayoutAlign="end center">
                    <calsoft-hoveremenu></calsoft-hoveremenu>
                </div>
            </div>
            <div>
                <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'"
                    [categoryArray]="item"></calsoft-Menu>
            </div>
        </div>


        <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
            fxLayoutAlign="space-between center" style="height: auto;z-index: 1000;">

            <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;">

                <!-- <div fxFlex="25">
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <calsoft-hoveremenu></calsoft-hoveremenu>
                    </div>
                </div> -->

                <div fxLayout="row" fxLayoutGap="10px" fxFlex>

                    <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000; z-index: 1000;"
                        class="menu" [categoryArray]="item"></calsoft-Menu>

                </div>

            </mat-toolbar-row>
        </mat-toolbar>
    </header>
</div>
<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
    </div>
    
    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>

        
</div>